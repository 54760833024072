




























import {
  Component, Prop, PropSync, Vue,
} from 'vue-property-decorator';

@Component
export default class NotCompleteModal extends Vue {
  @PropSync('show', { type: Boolean })
   syncedShow!: Boolean;

  @Prop()
  readonly options!: Record<any, any>;

  get validText() {
    return this.options.validText || 'Voir le Récapitulatif partiel';
  }

  close() {
    this.$emit('update:show', false);
  }

  valid() {
    this.$emit('valid');
    this.close();
  }
}
